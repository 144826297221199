import { CSpinner } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import authen from '../../models/authen';

function AuthChecker({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    let model = new authen()
    model.getUser().then(res => {
      console.log(res)
      if(res && res['result']){
        window.user = res['data']
        setIsAuthenticated(true)
      }
    })
  }, []);

  if (!isAuthenticated) {
    return <div className="pt-3 text-center">
      <CSpinner color="primary" variant="grow" />
    </div>;
  }

  return children;
}

export default AuthChecker;